<template>
  <div class="mes-payes">
    <Banner>
      <div class="banner-content">
        <MeepIconSocial class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.socials") }}</span>
      </div>
    </Banner>

    <div
      :class="{
        'mes-payes-content--lcm': isLCM,
        'mes-payes-content--jepa': isJEPA,
      }"
      class="mes-payes-content page-layout"
    >
      <LoadingCard v-if="isLoading" />

      <md-card v-else class="meep-form">
        <PageHeader
          :has-actions="false"
          :has-back="true"
          :has-search="false"
          :title="$t('socials.pay.title')"
          @back="goBack"
        />
        <!-- le formulaire de création -->
        <md-card-content>
          <!-- Numéro de SIREN -->
          <div class="meep-form-column socials-form">
            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">1</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.mes.section-1-title") }}
              </p>
            </div>

            <!-- Company Select -->
            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.company") }}
                </label>

                <md-select v-model="$v.form.companyId.$model">
                  <md-option
                    v-for="(company, index) in companies"
                    :key="index"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </md-option>
                </md-select>
                <template v-if="$v.form.companyId.$dirty">
                  <span v-show="!$v.form.companyId.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Employee Select -->
            <div v-if="this.form.companyId" class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.employee") }}
                </label>

                <md-select
                  v-model="form.employee"
                  @md-selected="changeEmployee"
                >
                  <md-option
                    v-for="(employee, index) in companyEmployees"
                    :key="index"
                    :value="index"
                  >
                    {{ employee.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <!-- Last Name -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.lastName") }}</label>
                <md-input v-model="$v.form.lastName.$model"></md-input>
              </md-field>
              <template v-if="$v.form.lastName.$dirty">
                <span v-show="!$v.form.lastName.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.form.lastName.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- First Name -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.firstName") }}</label>
                <md-input v-model="$v.form.firstName.$model"></md-input>
              </md-field>
              <template v-if="$v.form.firstName.$dirty">
                <span v-show="!$v.form.firstName.alphaSpace" class="meep-error">
                  {{ $t("meep-errors.alphaSpace") }}
                </span>
                <span v-show="!$v.form.firstName.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">2</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.mes.section-2-title") }}
              </p>
            </div>

            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("socials.mes.conge") }}
                </label>

                <md-select v-model="form.conge">
                  <md-option
                    v-for="(conge, index) in conge"
                    :key="index"
                    :value="conge.value"
                  >
                    {{ conge.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <!-- Begin Date -->
            <div class="meep-input">
              <md-datepicker v-model="$v.form.beginDate.$model">
                <label>
                  {{ $t("socials.mes.beginDate") }}
                </label>
              </md-datepicker>
              <template v-if="$v.form.endDate.$dirty">
                <span v-show="!$v.form.endDate.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <!-- End Date -->
            <div class="meep-input">
              <md-datepicker v-model="$v.form.endDate.$model">
                <label>
                  {{ $t("socials.mes.endDate") }}
                </label>
              </md-datepicker>
              <template v-if="$v.form.endDate.$dirty">
                <span v-show="!$v.form.endDate.required" class="meep-error">
                  {{ $t("meep-errors.required") }}
                </span>
              </template>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">3</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.mes.section-3-title") }}
              </p>
            </div>

            <!-- Prime -->
            <Chooser
              v-model="form.prime"
              :items="[
                { label: 'Brut', value: 'brut' },
                { label: 'Net', value: 'net' },
              ]"
              :label="$t('socials.mes.section-3-title')"
              :value="form.prime"
            />

            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.amount") }}</label>
                <md-input
                  v-model.number="$v.form.montant_prime.$model"
                ></md-input>
              </md-field>
              <template v-if="$v.form.montant_prime.$dirty">
                <span
                  v-show="!$v.form.montant_prime.isNumber"
                  class="meep-error"
                >
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </div>
          </div>

          <div class="meep-form-column socials-form">
            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">4</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.mes.section-4-title") }}
              </p>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.amount") }}</label>
                <md-input v-model="$v.form.acompte.$model"></md-input>
              </md-field>
              <template v-if="$v.form.acompte.$dirty">
                <span v-show="!$v.form.acompte.isNumber" class="meep-error">
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">5</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.mes.section-5-title") }}
              </p>
            </div>
            <!-- check -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.amount") }}</label>
                <md-input v-model="$v.form.salaire.$model"></md-input>
              </md-field>
              <template v-if="$v.form.salaire.$dirty">
                <span v-show="!$v.form.salaire.isNumber" class="meep-error">
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">6</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.mes.section-6-title") }}
              </p>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.mes.note") }}</label>
                <md-input v-model="$v.form.bulletin.$model"></md-input>
              </md-field>
              <template v-if="$v.form.bulletin.$dirty">
                <span v-show="!$v.form.bulletin.isNumber" class="meep-error">
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">7</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.mes.section-7-title") }}
              </p>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.mes.ticket") }}</label>
                <md-input v-model="$v.form.restaurantTicket.$model"></md-input>
              </md-field>
              <template v-if="$v.form.restaurantTicket.$dirty">
                <span
                  v-show="!$v.form.restaurantTicket.isNumber"
                  class="meep-error"
                >
                  {{ $t("meep-errors.number") }}
                </span>
              </template>
            </div>

            <div class="meep-form-column-title">
              <p class="meep-form-column-title-number">8</p>
              <p class="meep-form-column-title-text">
                {{ $t("socials.mes.section-8-title") }}
              </p>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("socials.mes.comment") }}</label>
                <md-input v-model="$v.form.comment.$model"></md-input>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right create-button">
              <md-button class="md-raised md-primary" @click.native="save">
                {{ $t("socials.button-text") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import companiesModel from "../../model/companies";
import employeeModel from "../../model/employee";
import PageHeader from "@/components/PageHeader";
import Banner from "@/components/Banner";
import MeepIconSocial from "@/components/icons/MeepIconSocial.vue";
import LoadingCard from "@/components/LoadingCard";
import { alphaSpace, isNumber } from "../../services/vuelidate";
import { required } from "vuelidate/lib/validators";
import { filterEmployeesCompanyId } from "@/services/util";
import FilesModel from "@/model/files";
import Chooser from "@/components/Chooser.vue";
import { SOCIALS } from "@/constants";

export default {
  name: "MesPayes",

  components: {
    LoadingCard,
    Banner,
    MeepIconSocial,
    PageHeader,
    Chooser,
  },

  computed: {
    ...mapGetters(["isJEPA", "isJEP", "isLCM"]),
    companyEmployees() {
      return filterEmployeesCompanyId(this.employees, this.form.companyId);
    },
  },

  data() {
    return {
      groups: [],
      isLoading: false,
      form: {
        lastName: null,
        firstName: null,
        employee: null,
        conge: "",
        beginDate: null,
        endDate: null,
        prime: "brut",
        montant_prime: 0,
        acompte: 0,
        salaire: 0,
        bulletin: 0,
        comment: "",
        type: SOCIALS.PAYE,
        companyId: null,
        restaurantTicket: 0,
      },
      fileName: null,
      companies: [],
      employees: [],
      conge: [
        { text: "CONGÉS PAYES", value: "payes" },
        { text: "CONGÉS EXCEPTIONNELS", value: "exceptionnels" },
        { text: "CONGÉS SANS SOLDE", value: "sanssolde" },
      ],
    };
  },

  validations: {
    form: {
      firstName: {
        alphaSpace,
        required,
      },
      lastName: {
        alphaSpace,
        required,
      },
      companyId: {
        required,
      },
      montant_prime: {
        isNumber,
      },
      acompte: {
        isNumber,
      },
      salaire: {
        isNumber,
      },
      bulletin: {
        isNumber,
      },
      restaurantTicket: {
        isNumber,
      },
      comment: {},
      beginDate: { required },
      endDate: { required },
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    async loadData() {
      try {
        this.isLoading = true;
        this.companies = await companiesModel.getAll();
        this.employees = await employeeModel.getAllEmployee();

        this.isLoading = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.isLoading = false;
      }
    },

    async save() {
      const dataObject = { data: this.form };
      const result = !this.$v.$invalid;
      if (result) {
        try {
          await FilesModel.uploadDsn(dataObject);

          this.$toasted.global.AppSucces({
            message: this.$t("socials.mes.success"),
          });
          this.$router.push("/dashboard/sociale/");
        } catch (error) {
          this.$toasted.global.AppError({
            message: error.msg,
          });
        }
      } else {
        this.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },

    changeEmployee(index) {
      this.form.lastName = this.companyEmployees[index].value.lastName;
      this.form.firstName = this.companyEmployees[index].value.firstName;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .mes-payes {
    .page-header {
      margin: 0;
      .page-header__item {
        min-height: unset;
        margin: 0;
      }

      .page-header__actions {
        display: flex;
      }
    }

    &-content {
      padding: toRem(30);

      .md-card.meep-form {
        @include for-lg {
          padding: toRem(34) toRem(46) toRem(46) toRem(50);
        }

        .md-card-content {
          .meep-form-column-title {
            margin: toRem(16) 0;
            @include for-lg {
              margin: toRem(32) 0 toRem(16);
            }

            .meep-form-column-title-text {
              font-size: toRem(12);
              @include for-lg {
                font-size: toRem(16);
              }
            }
          }

          .chooser-title {
            display: none;
          }

          .socials-form {
            .chooser {
              .v-radio .v-label {
                font-size: toRem(9);
                @include for-lg {
                  font-size: toRem(15);
                }
              }
            }

            .md-button {
              margin-right: 0;
              font-size: toRem(9);
              @include for-lg {
                font-size: toRem(14);
              }
            }
          }
        }
      }

      .md-card {
        .md-card-content {
          .socials-form.meep-form-column {
            .meep-form-column-title {
              margin: toRem(23) 0 toRem(12) 0;
              gap: toRem(12);
              @include for-lg {
                margin: toRem(32) 0 toRem(16) 0;
                gap: toRem(16);
              }

              .meep-form-column-title-text {
                font-size: toRem(11);
                @include for-lg {
                  font-size: toRem(16);
                }
              }
            }

            &:first-child {
              .meep-form-column-title {
                margin-bottom: toRem(5);
                @include for-lg {
                  margin: toRem(32) 0 toRem(11) 0;
                }
              }
            }

            .md-button {
              font-size: toRem(9);
              margin-right: 0;
              @include for-lg {
                font-size: toRem(14);
              }
            }

            .meep-input {
              .md-field {
                margin: toRem(7) 0 0 0;
                @include for-lg {
                  margin: toRem(10) 0 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
